import { MAX_ONCE_FILE_LIMIT } from "constant";
import { useDropzone } from "react-dropzone";
import { useContext, useState } from "react";
import { GlobalContext } from "context";
import "./dropzone.css";
import { Input } from "ui-atoms";
import { capitalize } from "lodash";
import { SolButton, SolIconBubble } from "@solstice/sol-react";

interface IDropzone {
  acceptType?: any;
  dropFiles?: any[];
  setDropFiles?: any;
  setFileUrl?: any;
  className?: string;
  title?: string;
  name?: string;
  submitImg?: any;
  isImg?: boolean;
  isInteractive?: boolean;
  icon?: any;
}

const Dropzone: React.FC<IDropzone> = ({
  acceptType,
  setDropFiles,
  className,
  title = "Add images here,",
  name,
  submitImg,
  setFileUrl,
}) => {
  const onDrop = (acceptedFiles: any[]) => {
    setDropFiles([...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_ONCE_FILE_LIMIT,
    onDrop,
    multiple: true,
    ...acceptType,
  });

  return (
    <div className={className}>
      <div
        {...getRootProps({
          className:
            "w-full flex h-full cursor-pointer bg-jll-surface-base-secondary-subdued border border-dashed border-jll-stroke-default-1 rounded-xl justify-center items-center p-6",
        })}
      >
        <input {...getInputProps()} />
        <div className="flex items-center flex-col">
          <SolIconBubble icon="cloud_upload" size="40" className="mb-3"/>
          <p className="text-jll-text-base-subdued mb-3">Drag and drop your files</p>
          <div className="flex flex-row items-center space-x-2 mb-3">
            <div className="w-20 h-px bg-jll-stroke-default-1"/>
            <span className="text-jll-text-base-subdued text-xs">OR</span>
            <div className="w-20 h-px bg-jll-stroke-default-1"/>
          </div>
          <SolButton variant="outlined" size="small" className="sol-bg-transparent">Browse</SolButton>
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
