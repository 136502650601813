import {
  SolButton,
  SolIcon,
  SolIconBubble,
  SolTextField,
} from "@solstice/sol-react";
import { SET_ADD_TAG_MODAL, SET_ALERT_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import {
  postCityAPI,
  postCountryAPI,
  postIndustryAPI,
  postMarketAPI,
  postServiceAPI,
  postStateAPI,
} from "services";
import { Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { capitalize } from "utils";

interface AddTagModalProps {
  kind: "industry" | "service" | "country" | "state" | "city" | "market";
  loadData?: any;
}

const AddTagModal: React.FC<AddTagModalProps> = ({ kind, loadData }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { isAddTagModalOpen } = state;
  const [postIndustry] = useApiCall(postIndustryAPI);
  const [postService] = useApiCall(postServiceAPI);
  const [postCountry] = useApiCall(postCountryAPI);
  const [postState] = useApiCall(postStateAPI);
  const [postCity] = useApiCall(postCityAPI);
  const [postMarket] = useApiCall(postMarketAPI);
  const [tagName, setTagName] = useState("");
  const [isBlur, setIsBlur] = useState(false);

  useEffect(() => {
    if (!isAddTagModalOpen) return;
    setTagName("");
    setIsBlur(false);
  }, [isAddTagModalOpen]);

  const handleClose = () => {
    dispatch({
      type: SET_ADD_TAG_MODAL,
      payload: false,
    });
  };

  const handleSubmit = () => {
    try {
      let API;
      switch (kind) {
        case "service":
          API = postService;
          break;
        case "country":
          API = postCountry;
          break;
        case "state":
          API = postState;
          break;
        case "city":
          API = postCity;
          break;
        case "market":
          API = postMarket;
          break;
        default:
          API = postIndustry;
      }
      API({ name: tagName }).then((res: any) => {
        handleClose();
        loadData();
        if (!!res) Toast.success(`Successfully created tag "${tagName}"`);
        // dispatch({
        //   type: SET_ALERT_MODAL,
        //   payload: {
        //     open: true,
        //     kind: "success",
        //     title: `Successfully created tag "${tagName}"`,
        //     description: "Message content vel elit vel ex bibendum ullamcorper quis nec nunc. Morbi consectetur, quam non.",
        //     icon: "info",
        //     btn1: null,
        //     btn2: null,
        //   },
        // })
        // setTimeout(() => {
        //   dispatch({
        //     type: SET_ALERT_MODAL,
        //     payload: {
        //       open: false,
        //       kind: "",
        //       title: "",
        //       description: "",
        //       icon: "",
        //       btn1: null,
        //       btn2: null,
        //     },
        //   });
        // }, 1000)
      });
    } catch (err) {}
  };

  return (
    <Modal isOpen={isAddTagModalOpen} setIsOpen={handleClose} size="default">
      <Modal.Header>
        <div className="flex flex-row justify-between items-start">
          <SolIconBubble icon="note_add" size="56" />
          <SolButton variant="tertiary" onClick={handleClose}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <h3 className="text-xl mb-1">Add {capitalize(kind)}</h3>
          <p className="mb-6">
            Curabitur a ipsum eget dolor malesuada tristique
          </p>

          <SolTextField
            label={`${capitalize(kind)} name`}
            required
            placeholder="Sustainability"
            size="small"
            value={tagName}
            onSol-input={(e) => {
              setTagName(e?.detail as string);
            }}
            onSol-blur={() => setIsBlur(true)}
            errorLabel={
              isBlur && !tagName?.length ? "This field is required" : undefined
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end mt-6">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={handleClose}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            disabled={!tagName?.length}
            onSol-click={() => handleSubmit()}
          >
            Save
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTagModal;
