import cn from "classnames";
import { SolIcon } from "@solstice/sol-react";
import { SolIconNames } from "@solstice/sol-stencil";
import { Link, useLocation } from "react-router-dom";

const MENU_LIST = [
  { label: "Home", icon: "home", value: "home" },
  { label: "Profile management", icon: "manage_accounts", value: "profile" },
  { label: "Metadata management", icon: "database", value: "metadata" },
  { label: "JLL Office management", icon: "domain", value: "location" },
];

interface IGlobalLeftBar {
  isOpen: boolean;
  setIsOpen?: any;
}

const GlobalLeftBar: React.FC<IGlobalLeftBar> = ({ isOpen, setIsOpen }) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");

  const location = useLocation();

  const menuList = session?.is_superuser
    ? [
        ...MENU_LIST,
        { label: "User management", icon: "manage_accounts", value: "user" },
      ]
    : MENU_LIST;

  return (
    <div
      className={cn(
        "flex flex-col bg-jll-surface-base-default h-full min-w-[250px] justify-between",
        {}
      )}
    >
      <div className="flex-col flex">
        <div className="w-full py-3 flex justify-center">
          <button
            className={`px-3 py-2 h-9 flex flex-row items-center w-full mx-7 cursor-pointer`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <SolIcon icon="menu" className="text-white" size="20" />
            {isOpen && (
              <span className="text-white text-sm ml-5 transition-opacity duration-300">
                Menu
              </span>
            )}
          </button>
        </div>
        <div className="flex flex-col space-y-4">
          {menuList.map((menu, idx) => (
            <div
              className={cn("w-full flex items-center cursor-pointer", {
                "bg-jll-surface-utilities-overlay bg-opacity-20":
                  location?.pathname?.toLowerCase()?.includes(menu?.value),
              })}
              key={idx}
            >
              <Link
                to={`/${menu.value === "home" ? "" : menu.value}`}
                className={cn(
                  "px-3 py-2 h-9 w-full flex flex-row items-center w-full mx-7 cursor-pointer"
                )}
              >
                <SolIcon
                  icon={menu.icon as SolIconNames}
                  className="text-white"
                  size="20"
                />
                {isOpen && (
                  <span className="text-white text-sm ml-5 transition-opacity duration-300">
                    {menu.label}
                  </span>
                )}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-8">
        <div className="w-full flex items-center">
          <button className="px-3 py-2 h-9 w-full flex flex-row items-center w-full mx-7 cursor-pointer">
            <SolIcon
              icon="call"
              className="text-jll-dark-text-base-subdued"
              size="20"
            />
            {isOpen && (
              <span className="text-white text-sm ml-5 transition-opacity duration-300">
                Support
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalLeftBar;
