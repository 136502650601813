import queryString from "query-string";
import axios from "../axiosConfig";
import { AUTH_API_PREFIX_URL } from "constant";

export const logoutApi = async () => {
  const res = await axios.get(`${AUTH_API_PREFIX_URL}/auth/logout/`);
  return res;
};

export const refreshApi = async (props: any) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${AUTH_API_PREFIX_URL}/auth/refresh/?${param_str}`
  );
  return res;
};
