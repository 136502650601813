import { ArrowBackOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ProfileProps } from "types";
import { useState } from "react";
import MainBio from "./MainBio";
import SectionBio from "./SectionBio";
import { useParams, useSearchParams } from "react-router-dom";
import { LoadingPage } from "ui-molecules";
import { useApiCall } from "hooks";
import { postProfileGPTAPI } from "services";

interface BioProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
}

const Bio: React.FC<BioProps> = ({ profile, setProfile, isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("main");
  const { brokerId } = useParams();
  const [postProfileGPT] = useApiCall(postProfileGPTAPI)

  const handleGPT = () => {
    try {
      postProfileGPT({
        broker_profile: brokerId
      });
    } catch (err) {

    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-10">
          <div className="flex flex-row items-center space-x-6 mb-12">
            <SolButton
              variant={activeTab === "main" ? "primary" : "secondary"}
              onSol-click={() => setActiveTab("main")}
            >
              Main bio <SolIcon icon="lab_profile" />
            </SolButton>
            <SolButton
              variant={activeTab === "section" ? "primary" : "secondary"}
              onSol-click={() => setActiveTab("section")}
            >
              Bio Sections
              <SolIcon icon="add" />
            </SolButton>
            <SolIcon
              icon="info"
              className="!w-5 !h-5 text-jll-icon-base-subdued"
            />
          </div>

          {activeTab === "main" && (
            <MainBio profile={profile} setProfile={setProfile} />
          )}
          {activeTab === "section" && (
            <SectionBio profile={profile} setProfile={setProfile} />
          )}

          <div className="flex flex-row items-center mb-12">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Bio;
