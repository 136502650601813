import { ArrowBackOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getCityAPI,
  getCountryAPI,
  getMarketAPI,
  patchProfileAPI,
} from "services";
import { ProfileProps } from "types";
import { SelectAutoComplete } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import { getOptions } from "utils";
import * as Yup from "yup";

interface GeographyProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
}

const INITIAL_VALUES = {
  country: undefined,
  language: undefined,
  cities: [],
  markets: [],
};

const validationSchema = Yup.object().shape({
  country: Yup.object().required("This field is required"),
  language: Yup.string().required("This field is required"),
  markets: Yup.array().required("This field is required"),
});

const Geography: React.FC<GeographyProps> = ({
  profile,
  setProfile,
  isLoading,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const [getCountry] = useApiCall(getCountryAPI);
  // const [getState] = useApiCall(getStateAPI);
  const [getCity] = useApiCall(getCityAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [patchProfile] = useApiCall(patchProfileAPI);
  // const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "country") {
        if (profile?.country)
          formValues = {
            ...formValues,
            [key]: {
              label: profile?.country?.name,
              value: profile?.country?.id,
            },
          };
        return;
      }

      if (key === "cities") {
        let options: any = [];
        if (!!profile?.cities?.length) {
          options = profile?.cities?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        formValues = {
          ...formValues,
          [key]: options,
        };
        return;
      }
      if (key === "markets") {
        let options: any = [];
        if (!!profile?.markets?.length) {
          options = profile?.markets?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        formValues = {
          ...formValues,
          [key]: options,
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] ? (profile as any)?.[key] : null,
      };
    });

    setValues({
      ...formValues,
    });
  }, [profile]);

  const { setFieldValue, values, setValues, touched, errors, handleBlur } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  // const fetchCity = useMemo(() => {
  //   return async () => {
  //     if (!values?.country) return [];
  //     const response = await getCity({
  //       country: [(values?.country as any)?.value],
  //       active_status: [STATUS_ACTIVE],
  //       limit: 500,
  //     });
  //     return response?.docs;
  //   };
  // }, [values?.country]);

  // useEffect(() => {
  //   fetchCity().then((result) =>
  //     setCityOptions(
  //       result?.map((item: any) => ({ value: item?.pk, label: item?.name }))
  //     )
  //   );
  // }, [fetchCity]);

  const loadCountryOptions = async (keyword: string) => {
    return Promise.all([
      profile?.country && getCountry({ ids: [profile?.country?.id] }),
      getCountry({ keyword }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
    });
  };

  const loadMarketOptions = async (keyword: string) => {
    return Promise.all([
      !!profile?.markets?.length &&
        getMarket({
          ids: profile?.markets?.map((market) => market?.id) || [],
        }),
      getMarket({ keyword }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((market: any) => {
        return {
          value: market.pk,
          label: market?.name,
        };
      });
    });
  };

  const loadCityOptions = async (keyword: string) => {
    return Promise.all([
      !!profile?.cities?.length &&
        getCity({
          ids: profile?.cities?.map((city: any) => Number(city?.id)) || [],
          country: [(values?.country as any)?.value],
        }),
      getCity({ keyword, country: [(values?.country as any)?.value] }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((city: any) => {
        return {
          value: city?.pk || city?.id,
          label: city?.name,
        };
      });
    });
  };

  const handleUpdate = (key: "country" | "cities" | "language" | "markets") => {
    try {
      let value;
      switch (key) {
        case "country":
          value = (values?.[key] as any)?.value;
          break;

        case "markets":
        case "cities":
          value = (values?.[key] || [])?.map((item: any) => item?.value);
          break;

        default:
          value = values?.[key];
      }

      patchProfile({
        pk: profile?.id,
        [key]: value,
      }).then((res: any) => {
        setProfile({
          ...profile,
          [key]: res?.[key],
        });
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Geography</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Curabitur a ipsum eget dolor malesuada tristique
            </p>
          </div>
          <form>
            <div className="w-full flex flex-row space-x-10 mb-10">
              <SelectAutoComplete
                name="language"
                label="Preferred Language"
                required
                className="w-full !mb-0"
                size="sm"
                options={getOptions("language", meta)}
                value={getOptions("language", meta)?.find(
                  (item: any) => item?.value === values?.language
                )}
                onChange={(e) => {
                  setFieldValue("language", e?.value);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("language");
                }}
                error={
                  touched.language ? (errors.language as string) : undefined
                }
              />
              <SelectAutoComplete
                label="Market"
                className="w-full !mb-0"
                size="sm"
                name="markets"
                loadOptions={loadMarketOptions}
                isMulti
                value={values?.markets}
                onChange={(e) => {
                  setFieldValue("markets", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("markets");
                }}
                error={touched.markets ? (errors.markets as string) : undefined}
              />
              <div className="w-full" />
            </div>
            <div className="w-full flex flex-row space-x-10 mb-10">
              <SelectAutoComplete
                label="Country"
                required
                className="w-full !mb-0"
                size="sm"
                loadOptions={loadCountryOptions}
                value={values?.country}
                name="country"
                onChange={(e) => {
                  setFieldValue("country", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("country");
                }}
                error={touched.country ? (errors.country as string) : undefined}
              />
              <SelectAutoComplete
                name="city"
                label="City"
                className="w-full !mb-0"
                size="sm"
                loadOptions={loadCityOptions}
                // isDisabled={!cityOptions?.length}
                value={values?.cities}
                isMulti
                onChange={(e) => {
                  setFieldValue("cities", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("cities");
                }}
                error={touched.cities ? (errors.cities as string) : undefined}
              />
              <div className="w-full" />
            </div>
          </form>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "awards");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Geography;
