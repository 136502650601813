import axios from "axiosConfig";
import { SET_META } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import Cookies from "js-cookie";
import { OriginalHome } from "pages";
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getMetaAPI, getSessionAPI } from "services";
import { parseJSON } from "utils";

export const RequiredAuth: React.FC = () => {
  // const { oktaAuth, authState } = useOktaAuth();
  const [getSession, , sessionInfo, error] = useApiCall(getSessionAPI, true);
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const [getMeta, , metaInfo] = useApiCall(getMetaAPI, true);
  const isLocal = parseJSON(
    process.env?.REACT_APP_AUTH_API_PREFIX_URL || ""
  )?.includes("local")
    ? true
    : false;

  useEffect(() => {
    if (!isLocal)
      axios.defaults.headers.common["subscription-key"] = parseJSON(
        process.env?.REACT_APP_APIM_SUBSCRIPTION_KEY || ""
      );
    getSession();
    if (!meta) {
      getMeta().then((response: any) => {
        dispatch({
          type: SET_META,
          payload: response,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (sessionInfo) {
      if (sessionInfo?.mock_user) {
        sessionStorage.setItem("mainSession", JSON.stringify(sessionInfo));
        sessionStorage.setItem(
          "session",
          JSON.stringify(sessionInfo?.mock_user)
        );
      } else {
        sessionStorage.setItem("session", JSON.stringify(sessionInfo));
        sessionStorage.removeItem("mainSession");
      }

      if (!!Cookies.get("okta_tokens")) {
        const tokens = parseJSON(Cookies.get("okta_tokens") || "")
        sessionStorage.setItem("okta_tokens", JSON.stringify(tokens));
        Cookies.remove("okta_tokens");
      }
    } else {
      sessionStorage.removeItem("session");
    }
  }, [sessionInfo]);

  if (!error && sessionInfo && (!!meta || !!metaInfo)) {
    return (
      <>
        {/* <Pendo sessionInfo={sessionInfo} /> */}
        <Outlet />
      </>
    );
  }

  return <>{error && <OriginalHome />}</>;
};
