import { ArrowBackOutlined } from "@material-ui/icons";
import { SolButton, SolDropdown, SolIcon } from "@solstice/sol-react";
import { SEARCH_RESULT_LIMIT } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getIndustryAPI,
  getMarketAPI,
  getServiceAPI,
  patchProfileAPI,
} from "services";
import { ProfileProps } from "types";
import { SelectAutoComplete } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import * as Yup from "yup";

interface MarketProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
}

const INITIAL_VALUES = {
  industries: [],
  property_type: [],
  services: [],
};

const validationSchema = Yup.object().shape({
  industries: Yup.array().required("This field is required"),
  services: Yup.array().required("This field is required"),
  property_type: Yup.array().required("This field is required"),
});

const Market: React.FC<MarketProps> = ({ profile, setProfile, isLoading }) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const [getIndustry] = useApiCall(getIndustryAPI);
  const [getService] = useApiCall(getServiceAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [patchProfile] = useApiCall(patchProfileAPI);

  const typeOptions = Object.keys(
    meta?.["brokerprofile"]?.["property_type"] || []
  )?.map((key: number | string) => ({
    value: key,
    label: meta?.["brokerprofile"]?.["property_type"]?.[key]?.value,
  }));

  const { handleBlur, setFieldValue, values, setValues } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "property_type") {
        formValues = {
          ...formValues,
          [key]: typeOptions?.filter((option: any) =>
            profile?.property_type?.includes(option?.value)
          ),
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: ((profile as any)?.[key] || [])?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        })),
      };
    });
    setValues({
      ...formValues,
    });
  }, [profile]);

  const loadIndustryOptions = async (keyword: string) => {
    return await getIndustry({
      keyword,
      limit: SEARCH_RESULT_LIMIT,
      page: 1,
    }).then((res: any) => {
      return res.docs.map((industry: any) => {
        return {
          value: industry.pk,
          label: industry?.name,
        };
      });
    });
  };

  const loadServiceOptions = async (keyword: string) => {
    return await getService({
      keyword,
      limit: SEARCH_RESULT_LIMIT,
      page: 1,
    }).then((res: any) => {
      return res.docs.map((service: any) => {
        return {
          value: service.pk,
          label: service?.name,
        };
      });
    });
  };

  const loadMarketOptions = async (keyword: string) => {
    return await getMarket({
      keyword,
      limit: SEARCH_RESULT_LIMIT,
      page: 1,
    }).then((res: any) => {
      return res.docs.map((service: any) => {
        return {
          value: service.pk,
          label: service?.name,
        };
      });
    });
  };

  const handleUpdate = (key: "industries" | "services" | "property_type") => {
    try {
      patchProfile({
        pk: profile?.id,
        [key]: values?.[key]?.map((item: any) => item?.value),
      }).then((res: any) => {
        if (!res) return;
        setProfile({
          ...profile,
          [key]: res?.[key],
        });
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Market</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Curabitur a ipsum eget dolor malesuada tristique
            </p>
          </div>
          <div className="grid grid-cols-3 gap-10 mb-10">
            <SelectAutoComplete
              name="industries"
              label="Industry"
              required
              loadOptions={loadIndustryOptions}
              isMulti
              value={values.industries}
              onChange={(values) => {
                setFieldValue("industries", values);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
                handleUpdate("industries");
              }}
              className="cols-span-1 w-full"
              isCustomMenu
            />
            <SelectAutoComplete
              name="services"
              label="Services"
              required
              loadOptions={loadServiceOptions}
              isMulti
              value={values.services}
              onChange={(values) => {
                setFieldValue("services", values);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
                handleUpdate("services");
              }}
              className="cols-span-1 w-full"
            />
            <SelectAutoComplete
              name="property_type"
              label="Property Type"
              required
              options={typeOptions}
              isMulti
              value={values?.property_type}
              onChange={(values) => {
                setFieldValue("property_type", values);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
                handleUpdate("property_type");
              }}
              className="cols-span-1 w-full"
            />
          </div>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "geography");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "bio");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Market;
