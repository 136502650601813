import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import JLLLogo from "assets/images/jll_logo.svg";
import { SolAvatar, SolButton, SolIcon } from "@solstice/sol-react";
import { capitalize, capitalizeName } from "utils";
import { useContext } from "react";
import { GlobalContext } from "context";
import {
  SET_ADD_PROFILE_MODAL,
  SET_ADD_TAG_MODAL,
  SET_LOADING_MODAL,
  URLS,
} from "constant";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useApiCall } from "hooks";
import { logoutApi } from "services";
import oktaAuth from "oktaConfig";

const TopBar = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [logout] = useApiCall(logoutApi);
  const [searchParams] = useSearchParams();
  const isProfile =
    location?.pathname?.replaceAll("/", "")?.toLowerCase() === "profile";
  const isMetadata = location?.pathname?.includes("metadata");
  const isLocation =
    location?.pathname?.replaceAll("/", "")?.toLowerCase() === "location";
  const isUser = location?.pathname?.includes("user");
  let subLabel = "";
  let tagLabel = "";

  if (location?.pathname?.includes("profile")) {
    subLabel = "Profile management";
    tagLabel = isProfile ? "Profile" : "";
  } else if (isMetadata) {
    subLabel = "Metadata management";
    switch (searchParams?.get("tab")) {
      case "service":
      case "country":
      case "industry":
      case "state":
      case "city":
      case "market":
        tagLabel = capitalize(searchParams?.get("tab") || "");
        break;
      default:
        tagLabel = "Industry";
    }
  } else if (location?.pathname?.includes("location")) {
    subLabel = "JLL office Location management";
    tagLabel = isLocation ? "Location" : "";
  } else if (isUser) {
    subLabel = "User Management";
    tagLabel = "";
  }

  const onClickLogout = async () => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Logging out...",
      },
    });
    logout()
      .then((data: any) => {
        window.location.href = data?.redirect;
      })
      .finally(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
      });
  };

  return (
    <div
      className="w-full h-[80px] flex items-center px-10 justify-between bg-jll-surface-base-default cursor-pointer"
      data-theme="dark"
    >
      <Link to="/" className="flex flex-row items-center space-x-3">
        <LazyLoadImage src={JLLLogo} className="h-full" />
        <span className="text-white">
          Broker Profile Management {!!subLabel?.length && ` | ${subLabel}`}
        </span>
      </Link>

      {/* Header */}
      <div className="h-full flex flex-row items-center">
        <div className="flex flex-row items-center space-x-6">
          {!!tagLabel?.length && (
            <SolButton
              onClick={() => {
                if (isProfile)
                  dispatch({
                    type: SET_ADD_PROFILE_MODAL,
                    payload: true,
                  });
                else if (isMetadata)
                  dispatch({
                    type: SET_ADD_TAG_MODAL,
                    payload: true,
                  });
                else if (isLocation) navigate(`${URLS.LOCATION.ROOT}/new`);
              }}
              variant="secondary"
            >
              {`Add ${tagLabel}`}
              <SolIcon icon="add" />
            </SolButton>
          )}
          <div className="">
            {/* <SolAvatar
              name={capitalizeName(session?.get_full_name)}
              status="online"
              size="24"
            />
            <span className="text-sm text-white">
              {session?.get_full_name || session?.email}
            </span> */}
            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <MenuButton className="flex flex-row items-center space-x-3">
                    <SolAvatar
                      name={capitalizeName(session?.get_full_name)}
                      status="online"
                      size="24"
                    />
                    <span className="text-sm text-white">
                      {session?.get_full_name || session?.email}
                    </span>
                  </MenuButton>
                  {open && (
                    <MenuItems
                      anchor="bottom end"
                      transition
                      className="mt-2 w-36 origin-top-right rounded-md border border-white/5 bg-white shadow-xl p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                    >
                      <MenuItem>
                        <button
                          onClick={onClickLogout}
                          className="group flex w-full items-center rounded py-1 px-3 hover:bg-jll-stroke-interaction hover:bg-opacity-10"
                        >
                          Sign out
                        </button>
                      </MenuItem>
                    </MenuItems>
                  )}
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
