import { LayoutBase } from "ui-organisms";
import cn from "classnames";
import { GlobalLeftBar, ListLeftSide, TopBar } from "ui-molecules";
import { useState } from "react";

interface ILayoutDetail {
  children: any;
  className?: string;
}

const LayoutDetail: React.FC<ILayoutDetail> = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <LayoutBase>
      <TopBar />
      <div className="absolute top-[80px] left-0 right-0 bottom-0 ">
        <div className="w-full h-full relative flex flex-row">
          <GlobalLeftBar isOpen={isOpen} setIsOpen={setIsOpen} />
          <div
            className={cn("absolute right-0 top-0 bottom-0 transition-width duration-300 flex flex-row", {
              "left-[250px]": isOpen,
              "left-[104px]": !isOpen,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </LayoutBase>
  );
};

export default LayoutDetail;
