import { ArrowBackOutlined } from "@material-ui/icons";
import { SolButton, SolIcon, SolTextField } from "@solstice/sol-react";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { patchProfileAPI } from "services";
import { ProfileProps } from "types";
import { LoadingPage } from "ui-molecules";
import * as Yup from "yup";

interface AwardsProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
}

const INITIAL_VALUES = {
  award: "",
  organization: "",
};

const validationSchema = Yup.object().shape({
  award: Yup.object().required("This field is required"),
  organization: Yup.string().required("This field is required"),
});

const Awards: React.FC<AwardsProps> = ({ profile, setProfile, isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] ? (profile as any)?.[key] : null,
      };
    });

    setValues({
      ...formValues,
    });
  }, [profile]);

  const { setFieldValue, values, setValues, touched, errors, handleBlur } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  const handleUpdate = (key: string, value: string) => {
    try {
      patchProfile({
        pk: profile?.id,
        [key]: value,
      }).then((res: any) => {
        if (!res) return;
        setProfile({
          ...profile,
          [key]: value,
        });
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Awards/affiliations</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Curabitur a ipsum eget dolor malesuada tristique
            </p>
          </div>
          <form className="mb-10">
            <div className="w-full grid grid-cols-3 gap-10">
              <SolTextField
                label="Name of the award"
                size="small"
                required
                className="col-span-1 w-full"
                value={values.award}
                onSol-input={(e: any) => setFieldValue("award", e?.detail)}
                onBlur={(e) => {
                  handleUpdate("award", values?.award);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate("award", values?.award);
                  }
                }}
              />

              <SolTextField
                label="Name of the organization"
                size="small"
                required
                className="col-span-1 w-full"
                value={values.organization}
                onSol-input={(e: any) =>
                  setFieldValue("organization", e?.detail)
                }
                onBlur={(e) => {
                  handleUpdate("organization", values?.organization);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate("organization", values?.organization);
                  }
                }}
              />
            </div>
          </form>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "license");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "geography");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Awards;
